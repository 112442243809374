import { Alert, Button, CircularProgress } from "@mui/material"
import React, { useState } from "react"

import { useAuth } from "src/lib/auth/useAuth"
import LoginModal from "./LoginModal"

interface AuthComponentProps {
  size?: "small" | "medium" | "large"
  buttonText?: string
  buttonIcon?: React.ReactNode
}

const AuthComponent: React.FC<AuthComponentProps> = ({
  size = "small",
  buttonText = "Sign Up",
  buttonIcon
}) => {
  const { loading, error } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (loading) {
    return <CircularProgress size={24} />
  }

  return (
    <div>
      <Button
        variant="contained"
        size={size}
        color="primary"
        startIcon={buttonIcon}
        onClick={() => setIsModalOpen(true)}>
        {buttonText}
      </Button>
      <LoginModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Authentication error: {error.message}
        </Alert>
      )}
    </div>
  )
}

export default AuthComponent
