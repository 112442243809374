import { Box, CircularProgress } from "@mui/material"
import React, { useEffect, useState } from "react"

interface DelayedFallbackProps {
  delay?: number
  fullScreen?: boolean
}

export const DelayedFallback: React.FC<DelayedFallbackProps> = ({
  delay = 300,
  fullScreen = true
}) => {
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), delay)
    return () => clearTimeout(timer)
  }, [delay])

  if (!showLoading) return null

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: fullScreen ? "100vh" : "100%",
        width: "100%",
        transition: "opacity 0.2s",
        opacity: showLoading ? 1 : 0
      }}>
      <CircularProgress />
    </Box>
  )
}
