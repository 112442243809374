import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User
} from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { auth, db, functions } from "src/lib/firebase/firebaseInitialize"
import { QUICKSTART_PREFERENCE_KEY } from "../common/quickstartPreference"

// Cache for user data
const userCache = new Map<string, { handle: string; signupSource: string }>()

// Initialize providers once
const googleProvider = new GoogleAuthProvider()
const microsoftProvider = new OAuthProvider("microsoft.com")

const processIDSHubSignUp = httpsCallable(functions, "idsHubProcessSignUp")

// Utility function to handle post-authentication tasks
const handlePostAuth = async (user: User): Promise<void> => {
  if (!user) return

  try {
    await processIDSHubSignUp()
  } catch (error) {
    console.error("Error processing IDS Hub signup:", error)
    throw error
  }
}

const clearAuthData = () => {
  userCache.clear()
  localStorage.removeItem(QUICKSTART_PREFERENCE_KEY)
  // Don't clear TOS and telemetry as they should persist
}

export const getSignupSource = async (user: User): Promise<string> => {
  if (!user?.uid) return "unknown"

  // Check cache first
  const cachedData = userCache.get(user.uid)
  if (cachedData) return cachedData.signupSource

  try {
    const userDoc = await getDoc(doc(db, "users", user.uid))
    const data = userDoc.data() || {}

    // Update cache
    userCache.set(user.uid, {
      handle: data.handle || "",
      signupSource: data.signupSource || "unknown"
    })

    return data.signupSource || "unknown"
  } catch (error) {
    console.error("Error getting signup source:", error)
    return "unknown"
  }
}

export const signInWithGoogle = async (): Promise<User> => {
  try {
    const { user } = await signInWithPopup(auth, googleProvider)
    await handlePostAuth(user)
    return user
  } catch (error) {
    console.error("Error during Google authentication:", error)
    throw error
  }
}

export const signInWithMicrosoft = async (): Promise<User> => {
  try {
    const { user } = await signInWithPopup(auth, microsoftProvider)
    await handlePostAuth(user)
    return user
  } catch (error) {
    console.error("Error during Microsoft authentication:", error)
    throw error
  }
}

export const signUpWithEmail = async (
  email: string,
  password: string,
  name: string
): Promise<User> => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password)
    await updateProfile(user, { displayName: name })
    await handlePostAuth(user)
    return user
  } catch (error) {
    console.error("Error during email sign up:", error)
    throw error
  }
}

export const signInWithEmail = async (
  email: string,
  password: string
): Promise<User> => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password)
    await handlePostAuth(user)
    return user
  } catch (error) {
    console.error("Error during email sign in:", error)
    throw error
  }
}

export const resetPassword = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email)
  } catch (error) {
    console.error("Error sending password reset email:", error)
    throw error
  }
}

export const logOut = async (): Promise<void> => {
  try {
    clearAuthData() // Clear cache on logout
    await signOut(auth)
  } catch (error) {
    console.error("Error during sign out:", error)
    throw error
  }
}

export const getCurrentUserHandle = async (): Promise<string | null> => {
  const user = auth.currentUser
  if (!user) return null

  // Check cache first
  const cachedData = userCache.get(user.uid)
  if (cachedData) return cachedData.handle

  try {
    const userDoc = await getDoc(doc(db, "users", user.uid))
    const data = userDoc.data() || {}

    // Update cache
    userCache.set(user.uid, {
      handle: data.handle || "",
      signupSource: data.signupSource || "unknown"
    })

    return data.handle || null
  } catch (error) {
    console.error("Error getting user handle:", error)
    return null
  }
}

export const createHandleForExistingUser = async (): Promise<string> => {
  const createUserHandleFunction = httpsCallable(functions, "createUserHandle")
  try {
    const result = await createUserHandleFunction()
    return (result.data as { handle: string }).handle
  } catch (error) {
    console.error("Error creating handle for existing user:", error)
    throw error
  }
}

export const deleteAccount = async (): Promise<void> => {
  const deleteAccountFunction = httpsCallable(functions, "deleteAccount")
  try {
    await deleteAccountFunction()
    clearAuthData() // Clear cache on account deletion
    await signOut(auth)
  } catch (error) {
    console.error("Error deleting account:", error)
    throw error
  }
}

// Auth state listener setup
export const setupAuthListener = (
  callback: (user: User | null) => void
): (() => void) => {
  return onAuthStateChanged(auth, callback)
}
