import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import Navbar from "./Navbar"

const NAVBAR_HEIGHT = "64px"

const BaseLayout: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
      }}>
      {/* Fixed navbar */}
      <Box sx={{ height: NAVBAR_HEIGHT, flexShrink: 0 }}>
        <Navbar />
      </Box>

      {/* Scrollable content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          backgroundColor: (theme) => theme.palette.background.default
        }}>
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column"
          }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default BaseLayout
