import { lazy, Suspense } from "react"
import { createBrowserRouter, RouteObject } from "react-router-dom"
import ErrorPage from "src/components/ErrorPage"
import BaseLayout from "src/layout/BaseLayout"

import { AuthWrapper } from "./AuthWrapper"
import { DelayedFallback } from "./DelayedFallback"
import {
  HandleCreationGuard,
  HomeRoute,
  ProtectedRoute,
  RedirectToHome,
  RequireHandle
} from "./RouteGuards"

// Lazy load components for better performance
const LandingPage = lazy(() => import("../pages/public/landing/LandingPage"))
const Dashboard = lazy(() => import("../pages/protected/dashboard/Dashboard"))
const Login = lazy(() => import("../pages/public/login/Login"))
const CreateHandle = lazy(
  () => import("../pages/protected/create-handle/CreateHandle")
)
const HandleProfile = lazy(
  () => import("../pages/protected/profile/[handle]/HandleProfile")
)
const ProjectList = lazy(
  () => import("../pages/protected/projects/[handle]/ProjectList")
)
const ProjectLayout = lazy(() => import("../layout/ProjectLayout"))
const CreateProject = lazy(
  () => import("../pages/protected/projects/[handle]/create/CreateProject")
)
const ProjectDetails = lazy(
  () =>
    import("../pages/protected/projects/[handle]/[projectname]/ProjectDetails")
)
const ProjectEdit = lazy(
  () =>
    import(
      "../pages/protected/projects/[handle]/[projectname]/edit/ProjectEdit"
    )
)

// Route definitions
export const routes: RouteObject[] = [
  {
    path: "/",
    element: <BaseLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <AuthWrapper>
            <Suspense fallback={<DelayedFallback />}>
              <HomeRoute
                authenticatedComponent={<Dashboard />}
                unauthenticatedComponent={<LandingPage />}
              />
            </Suspense>
          </AuthWrapper>
        )
      },
      {
        path: "login",
        element: (
          <AuthWrapper>
            <Suspense fallback={<DelayedFallback />}>
              <RedirectToHome>
                <Login />
              </RedirectToHome>
            </Suspense>
          </AuthWrapper>
        )
      },
      {
        path: "create-handle",
        element: (
          <HandleCreationGuard>
            <Suspense fallback={<DelayedFallback />}>
              <CreateHandle />
            </Suspense>
          </HandleCreationGuard>
        )
      },
      {
        path: "profile/:handle",
        element: (
          <RequireHandle>
            <Suspense fallback={<DelayedFallback />}>
              <HandleProfile />
            </Suspense>
          </RequireHandle>
        )
      },
      {
        path: "projects",
        element: (
          <RequireHandle>
            <Suspense fallback={<DelayedFallback />}>
              <ProjectLayout />
            </Suspense>
          </RequireHandle>
        ),
        children: [
          {
            path: ":handle",
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<DelayedFallback />}>
                    <ProjectList />
                  </Suspense>
                )
              },
              {
                path: "create",
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<DelayedFallback />}>
                      <CreateProject />
                    </Suspense>
                  </ProtectedRoute>
                )
              },
              {
                path: ":projectname",
                element: (
                  <Suspense fallback={<DelayedFallback />}>
                    <ProjectDetails />
                  </Suspense>
                )
              },
              {
                path: ":projectname/edit",
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={<DelayedFallback />}>
                      <ProjectEdit />
                    </Suspense>
                  </ProtectedRoute>
                )
              }
            ]
          }
        ]
      }
    ]
  }
]

export const router = createBrowserRouter(routes)
