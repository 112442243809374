import { Logout as LogoutIcon, ViewList } from "@mui/icons-material"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import AuthComponent from "src/components/auth/AuthComponent"
import { useAuth } from "src/lib/auth/useAuth"

// Styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  elevation: 0,
  zIndex: theme.zIndex.drawer + 2,
  height: "64px", // Equivalent to theme.sppx.headerHeight
  backgroundColor: "white"
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "white",
  borderBottom: `1px solid ${theme.palette.divider}`,
  color: "black",
  padding: 0,
  minHeight: "64px"
}))

const Navbar: React.FC = () => {
  const navigate = useNavigate()
  const { user, logOut, handle: userHandle } = useAuth()
  const [accountsMenuAnchor, setAccountsMenuAnchor] =
    useState<null | HTMLElement>(null)
  const isAccountsMenuOpen = Boolean(accountsMenuAnchor)

  const openAccountsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAccountsMenuAnchor(event.currentTarget)
  }

  const closeAccountsMenu = () => {
    setAccountsMenuAnchor(null)
  }

  const signOut = async () => {
    setAccountsMenuAnchor(null)
    await logOut()
    navigate("/")
  }

  return (
    <StyledAppBar>
      <StyledToolbar>
        {/* Logo and BETA chip */}
        <Button onClick={() => navigate("/")}>
          <Box sx={{ ml: "4px", height: "30px" }}>
            <img
              src="/images/logo_only_blue.svg"
              alt="Sortdesk company logo"
              style={{ width: "auto", height: "100%" }}
            />
          </Box>
          <Chip
            label="BETA"
            color="primary"
            variant="outlined"
            sx={{
              backgroundColor: "white",
              border: "none",
              marginLeft: "0.5em",
              height: "2em",
              "& span": {
                paddingLeft: "0.7em",
                paddingRight: "0.7em"
              },
              marginTop: "0.2em"
            }}
          />
        </Button>

        {/* Main content */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            gap: 2
          }}>
          {user ? (
            <Button
              onClick={() => navigate(`/projects/${userHandle}`)}
              variant="outlined"
              color="primary"
              startIcon={<ViewList />}>
              My Projects
            </Button>
          ) : null}
        </Box>

        {/* User menu or auth component */}
        <Box
          sx={{
            flexGrow: 0,
            ml: "auto",
            display: "flex",
            alignItems: "center",
            position: "relative"
          }}>
          {!user ? (
            <>
              <Typography
                sx={(theme) => ({
                  position: "absolute",
                  color: theme.palette.text.secondary,
                  marginRight: "1em",
                  width: "max-content",
                  right: "100%",
                  [theme.breakpoints.down("md")]: {
                    display: "none"
                  }
                })}>
                Start using IDS Hub for free
              </Typography>
              <AuthComponent />
            </>
          ) : (
            <>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={openAccountsMenu}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={
                    isAccountsMenuOpen ? "account-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={isAccountsMenuOpen ? "true" : undefined}>
                  <Avatar
                    variant="rounded"
                    sx={{ bgcolor: "#0d47a1", width: 40, height: 32 }}>
                    {user.displayName?.[0] || user.email?.[0]}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={accountsMenuAnchor}
                id="account-menu"
                open={isAccountsMenuOpen}
                onClose={closeAccountsMenu}
                onClick={closeAccountsMenu}
                sx={{ paddingTop: "0px" }}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0
                      }
                    }
                  }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Typography
                  sx={{
                    pl: "10px",
                    pr: "10px",
                    pt: "3px",
                    pb: "5px",
                    background: "#0d47a1",
                    color: "white",
                    minWidth: "150px",
                    borderTop: "1px solid #333333"
                  }}>
                  <strong>{user.displayName || user.email}</strong>
                </Typography>
                <Divider />
                <MenuItem onClick={() => navigate(`/profile/${userHandle}`)}>
                  View Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={signOut}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Sign out
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Navbar
